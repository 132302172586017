import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const GlazenPage = () => (
  <Layout>
    <Seo title="Glazen" />
    <section className="page-header">
      <h1>Glazen</h1>
    </section>    
    <section className="page-intro">
      <div className="page-intro-content">
        <p>Opvallend, trendy, duurzaam of klassiek….Hoe je volgende bril eruit moet zien, daar heb je vast zelf al een idee over!!! Maar heb je al eens nagedacht over de glazen? Welke brillenglazen zijn geschikt voor uw ogen en uw levensstijl? Wij helpen jou graag verder!</p>
        <p>Na het uitvoeren van een professionele oogmeting, geven wij jou advies over de best passende brillenglazen. Benieuwd naar de mogelijkheden? Kom dan zeker langs!!</p>
        <Link to="/contact" className="button bg-tdb-light hover:bg-tdb-dark text-white mr-2" title="Contacteer ons voor een afspraak ...">Afspraak maken</Link>
      </div>
      <div className="col-start-8 lg:col-start-6 col-end-13 lg:col-end-12 row-start-1 row-end-1 z-10 h-2/4 md:h-full flex justify-end items-end">
        <StaticImage
            src="../images/glazen.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Glazen"
            placeholder="blurred"
            className="inline-block mx-2 w-12/12 h-full min-h-full"
        />
      </div>
      <div className="section-bg-right col-start-9 lg:col-start-7 col-end-13 -top-16 -right-6 lg:right-4 h-full"></div>
    </section>

    <section className="section">
      <div className="section-content-right">
        <h2 className="pb-8">Soorten glazen</h2>
        <h4>Enkelvoudige brillenglazen voor veraf of dichtbij</h4>
        <p>Heb je moeite met in de verte kijken? Of zijn de letters in je favoriete boek wazig? Dan kunnen enkelvoudige brillenglazen dit oplossen. Enkelvoudige glazen zijn in verschillende soorten en varianten verkrijgbaar. Denk je dat uw glazen te dik zijn voor het fijne montuur? Dat hoeft niet! De glazen zijn tegenwoordig veel dunner en lichter in uitvoering.</p>
        <h4 className="pt-8">Multifocale brillenglazen, op elke afstand de juiste scherpte</h4>
        <p>Heb je moeite om zowel veraf als dichtbij te kijken? Dan bieden multifocale brillenglazen de uitkomst. In multifocale  brillenglazen worden meerdere sterktes geslepen, waardoor je maar één bril nodig hebt voor elke afstand.</p>
        <h4 className="pt-8">Computerbril</h4>
        <p>Waziger zicht op de computer, met je leesbril naar voor moeten buigen tijdens het werken aan de computer, onnatuurlijke houding? Is dit herkenbaar? Dan is een computerbril misschien iets voor jou. Een computerbril is een ideaal hulpmiddel om zonder klachten naar je beeldscherm te kunnen kijken in de juiste houding. Met een speciale coating op de glazen wordt computerwerken veel rustiger en aangenamer voor uw ogen.</p>
        <h4 className="pt-8">Gepolariseerde glazen</h4>
        <p>Gepolariseerde brillenglazen nemen alle schittering weg op horizontaal vlak. Denk maar aan de schitteringen op een nat wegdek of op de motorkap van de auto, schitteringen op water of sneeuw. Deze glazen zijn dan ook ideaal voor het autorijden, watersporten en uw wintervakantie.</p>

        {/* <div className="flex flex-wrap mt-10 ">
          <Link className="button bg-transparent text-dl-orange px-0 py-0" to="/" title="Meer informatie over hoortest ...">{"Meer info ->"}</Link>
        </div> */}
      </div>
      <div className="col-start-1 col-end-7 row-start-1 row-end-1 z-10 h-full flex justify-start items-center">
        <StaticImage
            src="../images/glazen_1.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Glazen"
            placeholder="blurred"
            className="opacity-0 hidden md:opacity-100 md:inline-block mx-2 w-4/6 h-1/2"
        />
        {/* <StaticImage
            src="../images/glazen_2.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogmeting"
            placeholder="blurred"
            className="opacity-0 hidden lg:opacity-100 lg:inline-block mx-2 w-4/12 h-1/4"
        /> */}
      </div>
      <div className="section-bg-left"></div>
    </section>
    <section className="section">
      <div className="section-content-left lg:col-end-7">
        <h2 className="pb-8">Onze diensten en garanties</h2>
        <ul className="list-disc list-outside ml-4">
          <li className="my-3">De PlusCard is uw kwaliteitscertificaat.</li>
          <li className="my-3">U krijgt 6 maanden gewenningsgarantie voor uw multifocale glazen.</li>
          <li className="my-3">U krijgt een 24 tot 36 maanden kwaliteitsgarantie. Vertonen uw glazen een kwaliteitsfout? Dan ontvangt u meteen nieuwe brillenglazen van hetzelfde type, met dezelfde correctiewaarde.</li>
          <li className="my-3">Multifocale glazen genieten van de gratis Traveller Service. Raakt uw bril beschadigd of verloren tijdens een reis in Europa? Dan leveren we binnen de 48u een set noodbrillen op uw vakantieadres</li>
        </ul>
        <h4 className="pt-8">2de paar glazen aan -50%</h4>
        <p>Heb je zin in een extra bril? Je draagt immers ook niet altijd dezelfde schoenen of kledij! Of je multifocale bril draag je voor alle dagelijkse activiteiten , maar een extra computerbril voor je werk is wel makkelijk? Wenst u een liever een zonnebril of sportbril op sterkte?</p>
        <p>Dat kan!!! Bij ons ontvangt u bij een nieuwe aankoop je <strong>tweede paar glazen aan de helft van de prijs</strong>. En dit tot 2 maanden na aankoop van je eerste bril!!</p>
        <Link to="/contact" className="button bg-tdb-light hover:bg-tdb-dark text-white mr-2" title="Contacteer ons voor een afspraak ...">Afspraak maken</Link>
      </div>
      <div className="col-start-7 col-end-13 row-start-1 row-end-1 z-10 h-full flex justify-end items-end">
        <StaticImage
            src="../images/glazen_2.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Glazen"
            placeholder="blurred"
            className="opacity-0 hidden md:opacity-100 md:inline-block mx-2 w-8/12 h-2/3"
        />
      </div>
      {/* @apply relative bg-tdb-light bg-opacity-20 col-start-7 col-end-11 row-start-2 row-end-2 block w-full h-full top-10 z-0; */}
      <div className="section-bg-right col-start-8"></div>
    </section>
</Layout>
)

export default GlazenPage
